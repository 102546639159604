import $ from 'jquery';

const Contact = {
  init() {
    //Find all tabs
    $('[data-target-id]').on('click', this.handleClick);
  },

  handleClick(e) {
    e.preventDefault();
    const $tab = $(e.currentTarget);
    const $tabContent = $($tab.attr('data-target-id'));

    if ($tab.is('.module-contact__tabs__tab--selected')) {
      $('.module-contact__tabs__tab').removeClass('module-contact__tabs__tab--selected');
      $('.module-contact__content').removeClass('module-contact__content--selected');
    } else {
      $('.module-contact__tabs__tab').removeClass('module-contact__tabs__tab--selected');
      $('.module-contact__content').removeClass('module-contact__content--selected');
      $tab.addClass('module-contact__tabs__tab--selected');
      $tabContent.addClass('module-contact__content--selected');
    }

  }
};

export default Contact;
