import $ from "jquery";

module.exports = function () {
		
    var elements = {
        loadMoreLink: null
    };
		
    var loadNew = function (url) {
        $.ajax({
            type: 'GET',
            cache: false,
            url: url,
            beforeSend: function ( ) {
                //Add opacity to button
                elements.loadMoreLink.addClass("button-load");
            },
            success: function (result) {
                var newContent = $(result.html);
                newContent.appendTo($('.search-result.clearfix'));

                elements.loadMoreLink.trigger('done-loading');

                // Modify or remove load more link
                if (result.hasMorePages) {
                    elements.loadMoreLink.removeClass('button-load').attr('href', result.loadMoreUrl);
                } else {
                    elements.loadMoreLink.remove();
                }

                // Scroll to position
                $('html, body').animate({
                    scrollTop: newContent.offset().top
                }, 500);
            },
            error: function ( ) {
                elements.loadMoreLink.remove();
            }
        });
    };

    var initialize = function () {
        
        elements.loadMoreLink = $("#fetchMore");

        elements.loadMoreLink.on('click', function (e) {

            e.preventDefault();

            var $self = $(this);

            //Change address in browser
            var nextPage = $self.attr("href");
            if ('history' in window && 'pushState' in history) {
                history.pushState(null, null, nextPage);
            }

            //Ajax request 
            loadNew(nextPage);
        });
    };
		
    return {
        init: initialize
    };
}();
