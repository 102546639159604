import $ from "jquery";

module.exports = function () {
	$(".toggle-support").on("click", function() {
		var context = $(this).parents(".support-box");
		
		if(context.is(".active")) {
			context.removeClass("active");
			$(".open", context).html("+");
		}
		else {
			context.addClass("active");
			$(".open", context).html("-");
		}
		return false;
	});
}();