﻿//import $ from "jquery";
//import validate from "jquery.validate";
//import unobtrusive from "jquery.validate.unobtrusive";
//import addValidation from "jquery.validate.unobtrusive.addValidation";

module.exports = function () {

    $.validator.unobtrusive.addValidation = function (form) {
        form.removeData("validator");
        $.validator.unobtrusive.parse(form);
    };

    $.validator.addMethod("enforcetrue", function (value, element, param) {
        return element.checked;
    });

    $.validator.addMethod("comparesubservices", function (value, element, param) {

        var $self = jQuery(element);
        var $other = $self.closest('li').find('.support-services');

        // If the other values isn't checked, we don't need to compare them
        if (!$other.prop('checked'))
            return true;

        return $self.prop('checked') == $other.prop('checked');
    });
    
    $.validator.unobtrusive.adapters.addBool('enforcetrue');

    $.validator.unobtrusive.adapters.addBool("comparesubservices");

    $.validator.setDefaults({
        ignore: ''
    });
}();
