﻿import $ from 'jquery';

module.exports = function () {

    var initialize = function() {
        initFormChange();
        initDetailInformation();
        //initScrollOnSearch();
    };

    var initFormChange = function () {
        var $form = $('#softwareform');
        $form.on('change', 'input', function() {
            refreshAdvancedSearch($form);
        });
        $form.on('change', 'select', function() {
            refreshAdvancedSearch($form);
        });
    };

    //var initScrollOnSearch = function() {
    //    var query = $('#softwareform input[type="search"]').val();
    //    if(query !== '') {
    //        $(window).scrollTop($('.article-main-content').offset().top);
    //    }
    //};

    var initDetailInformation = function () {
        var baseUrl = $('.software-results').data('details-url') + '?pageIdentifier=';

        $(".more-info").on("click", function () {
            var $self, $tr, $nextTr, $angle, url;
            $self = $(this);
            $tr = $self.parents('tr');

            if ($tr.data('loading')) {
                return false;
            }
			
            $angle = $('i', $self);

            if (!$tr.data('loaded')) {
                url = baseUrl  + $self.data('software-id');
                $tr.data('loading', true);

                $.when($.ajax(url)).then(function (data, status, jqXhr) {
                    $tr.data('loading', false);
                    $tr.data('loaded', true);
                    var $newTr = $(data.html);
                    $newTr.insertAfter($tr);
                    toggleDetailedInformation($tr, $newTr, $angle);
                });
            } else {
                $nextTr = $tr.next("tr");
                toggleDetailedInformation($tr, $nextTr, $angle);
            }

            return false;
        });
    };

    // Handles toggling details form
    var toggleDetailedInformation = function ($tr, $nextTr, $angle) {
        if ($tr.is(".active")) {
            $tr.removeClass("active");
            $nextTr.removeClass("active");
            $angle.addClass("icon-angle-down").removeClass("icon-angle-up");
        }
        else {
            $tr.addClass("active");
            $nextTr.addClass("active");
            $angle.addClass("icon-angle-up").removeClass("icon-angle-down");
        }
        return false;
    };

    // The idea here is to get the entire advanced form back, and rewrite it
    var refreshAdvancedSearch = function($form) {
        var area = $('#bg-adv-search');

        if (area.data('loading'))
            return;

        var url = $form.data('advanced-url') + '?' + $form.serialize();

        $.ajax({
            type: 'GET',
            cache: false,
            url: url,
            beforeSend: function () {
                area.data('loading', true);
            },
            success: function (result) {
                var newContent = $(result.html);
                area.text('').append($(newContent));
                area.data('loading', false);
            },
            error: function () {

            }
        });

    };

    return {
        init: initialize
    };
}();