﻿import $ from "jquery";

module.exports = function () {
    function validatelangdsiffror(ocrlangd1, ocrlangd2) {
        var ocrl1 = ocrlangd1.value.length
        var ocrl2 = ocrlangd2.value.length
        var f = 0

        if (ocrl1 != 0) {
            for (var lsiffra1 = 0; lsiffra1 < ocrl1; lsiffra1++) //kollar om längdsiffra1 är annat än siffror
            {
                if (f == 0 & ((ocrlangd1.value.substring(lsiffra1, lsiffra1 + 1) < '0') || (ocrlangd1.value.substring(lsiffra1, lsiffra1 + 1) > '9'))) {
                    f = f + 1
                }
            }
            if (ocrlangd1.value < 2) //kollar om ocrlangd1's värde är mindre än 2
            {
                f = f + 1
            }

            if (ocrlangd1.value > 25) //kollar om ocrlangd1's värde är mer än 25
            {
                f = f + 1
            }
        }

        if (ocrl2 != 0) {
            for (var lsiffra2 = 0; lsiffra2 < ocrl2; lsiffra2++) //kollar om längdsiffra1 är annat än siffror
            {
                if (f == 0 & ((ocrlangd2.value.substring(lsiffra2, lsiffra2 + 1) < '0') || (ocrlangd2.value.substring(lsiffra2, lsiffra2 + 1) > '9'))) {
                    f = f + 1
                }
            }


            if (ocrlangd2.value < 2) //kollar om ocrlangd1's värde är mindre än 2
            {
                f = f + 1
            }

            if (ocrlangd2.value > 25) //kollar om ocrlangd1's värde är mer än 25
            {
                f = f + 1
            }
        }

        if (f == 0) {
            return true
        } else {
            return false
        }

    }

    function validateallfields(ocravtal, ocrlangd1, ocrlangd2, ocrnummer) {

        var f = 0 //räknare för att hålla reda på om det är fel eller inte - är 0 om inte fel
        var kost
        kost = ocrnummer.value.length

        //alert ("Validering av fälten")
        if (ocravtal[0].checked == false & ocravtal[1].checked == false & ocravtal[2].checked == false & ocravtal[3].checked == false) {
            f = f + 1
        }

        if (ocrnummer.value == "") {
            f = f + 1
        }


        var b = 0
        for (var l = 0; l < kost; l++) //kollar om ocrnumret är annat än siffror
        {
            if (f == 0 & ((ocrnummer.value.substring(l, l + 1) < '0') || (ocrnummer.value.substring(l, l + 1) > '9'))) {
                f = f + 1
                if (b < 1) {
                    b = b + 1
                }
            }
        }

        if (kost < 2) //kollar om ocr-numret är mindre än två tecken
        {
            f = f + 1
        }


        if (kost > 25) {
            f = f + 1
        }


        if (f == 0) {
            return true
        } else {
            return false
        }
    }

    function modul(ocrnummer) {
        var res1 = 0
        var res = 0 //resultatet av sammanräkningen
        var ksiffra1 = 0
        var ksiffra = 0
        var asiffra = 0 //från OCR-numret aktuell siffra	
        var l = ocrnummer.value.length //antal siffror i ocrnummer-fältet
        var bsiffra = 0 // kontrollsiffran
        var ocrstring = ocrnummer.value // gör värdet i ocrnummer-fältet till en sträng	
        var b = 0
        var ocrl = l - 1 // för att inte räkna med sista siffran i 10-moduls-beräkningen
        var resm2 = 0 // andra siffran vid tal större än 9
        bsiffra = ocrstring.substr(ocrl, 1) // kontrollsiffran direkt tagen ur OCR-strängen
        var raknestart = l - 2 // ger positionen där vi ska börja räkna (från höger)
        var raknare = 0 // för att hålla reda på vilket varv vi är på


        for (var a = raknestart; a != -1; a--) //loop som går igenom ocrsträngen förutom checksiffran
        {
            asiffra = ocrstring.substr(a, 1) //asiffra får värdet av den aktuella siffran
            if (raknare % 2 == 0) // om aktuell siffra´s position är jämn (utifrån att vi börjar på 0)
            {
                res1 = 0
                res1 = 2 * asiffra
                if (res1 > 9) // om beräkningen blir 10 eller mer
                {
                    resm2 = res1 - 10 // räknar ut den andra siffran
                    res = res + 1 + resm2 // adderar siffrorna
                } else // om beräkningen blir mindre än 10
                {
                    res = res + res1
                }
            } else // om aktuell siffra´s position är ojämn (utifrån att vi börjar på 0)
            { //  udda varv
                res1 = 0
                res1 = 1 * asiffra
                res = res + res1
            }
            raknare = raknare + 1
        }
        ksiffra1 = res % 10 // tar bort tio-tals-siffran från res-variablen
        if (ksiffra1 == 0) {
            ksiffra = 0 //kontrollsiffran blir en nolla
        } else {
            ksiffra = 10 - ksiffra1 // räknar ut kontroll-siffran
        }
        asiffra = 0
        if (ksiffra == bsiffra) {
            return true
        } else {
            return false
        }
    }


    function mjukkontroll(ocrnummer) {
        var svar
        svar = modul(ocrnummer)
        if (svar) {
            document.getElementById('result').className = "correct1";
        } else {
            document.getElementById('result').className = "error1";
        }
    }

    function hardkontroll(ocrnummer) {
        var svar
        svar = modul(ocrnummer)
        if (svar) {
            document.getElementById('result').className = "correct2";
        } else {
            document.getElementById('result').className = "error2";
        }
    }

    function langdsiffra(ocrnummer) {
        var l = ocrnummer.value.length //antal siffror i ocrnummer-fältet
        var langd

        if (l > 9) {
            langd = l % 10 //modulus
        } else {
            langd = l
        }
        var ocrstring = ocrnummer.value // gör värdet i ocrnummer-fältet till en sträng
        var lpos = l - 2 // -2 eftersom den börjar på 0
        var ls = ocrstring.substr(lpos, 1)
        var lsiffra = parseInt(ls)
        var svar
        svar = modul(ocrnummer)
        if (svar) {
            if (lsiffra == langd) {
                document.getElementById('result').className = "correct3";
            } else {
                document.getElementById('result').className = "error3";
            }
        } else {
            document.getElementById('result').className = "error3";
        }
    }

    function fastlangd(ocrnummer, ocrlangd1, ocrlangd2) {

        var l = ocrnummer.value.length //antal siffror i ocrnummer-fältet
        var langd
        if (l > 9) {
            langd = l % 10 //modulus
        } else {
            langd = l
        }
        var ocrstring = ocrnummer.value // gör värdet i ocrnummer-fältet till en sträng
        var ls1 = ocrlangd1.value
        var lsiffra1 = parseInt(ls1)
        var ls2 = ocrlangd2.value
        var lsiffra2 = parseInt(ls2)
        var langdsvar

        langdsvar = validatelangdsiffror(ocrlangd1, ocrlangd2) //kör kollen så att de inskrivna längdsiffrorna är korrekta
        if (langdsvar) // kollar så längden är korrekt
        {
            var svar
            svar = modul(ocrnummer) // kör tio-modulen på ocrnumret
            if (svar) // om tio-.modulen gick bra
            {
                if (lsiffra1 == l) // kollar om ocrnumret's längd motsvarar den i ocrlangd1 angivna
                {
                    document.getElementById('result').className = "correct4";
                } else {
                    if (lsiffra2 == l) {
                        document.getElementById('result').className = "correct4";
                    } else {
                        document.getElementById('result').className = "error4";
                    }
                }
                // slut på kontrollen mot längdsiffrorna (lsiffra==
            } else // om tio-modulen inte gick bra
            {
                document.getElementById('result').className = "error4";
            }
            // slut på tio-modul-snurran	
        } else //om langdsiffrorna inte var korrekta
        {
            //alert("Längdsiffrorna är inte korrekta")
            document.getElementById('result').className = "lengthError1";
        }
    }


    function submitocr(ocravtal, ocrlangd1, ocrlangd2, ocrnummer) {
        var koll

         koll = validateallfields(ocravtal, ocrlangd1, ocrlangd2, ocrnummer)

        if (koll) {

            if (ocravtal[0].checked) {
                mjukkontroll(ocrnummer)
            }

            if (ocravtal[1].checked) {
                hardkontroll(ocrnummer)
            }

            if (ocravtal[2].checked) {
                langdsiffra(ocrnummer)
            }

            if (ocravtal[3].checked) {
                if (ocrlangd1.value == "" & ocrlangd2.value == "") {
                    //alert("Ingen längdsiffra ifylld")
                    document.getElementById('result').className = "lengthError2";
                } else {
                    fastlangd(ocrnummer, ocrlangd1, ocrlangd2)
                }
            }
        } else {
            if (ocravtal[0].checked) //generellt fel för mjuk avtal
            {
                document.getElementById('result').className = "generror1";
            }

            if (ocravtal[1].checked) //generellt fel för hårt avtal
            {
                document.getElementById('result').className = "generror2";
            }

            if (ocravtal[2].checked) //generellt fel för längdsiffra
            {
                document.getElementById('result').className = "generror3";
            }

            if (ocravtal[3].checked) //generellt fel för fast längd
            {
                if (ocrlangd1.value == "" & ocrlangd2.value == "") {
                    //alert("Ingen längdsiffra ifylld")
                    document.getElementById('result').className = "lengthError2";
                } else {
                    document.getElementById('result').className = "generror4";
                }
            }
            if (ocravtal[0].checked == false & ocravtal[1].checked == false & ocravtal[2].checked == false & ocravtal[3].checked == false) {
                //alert("Du har inte valt något OCR-avtal")
                document.getElementById('result').className = "controlError";
            }
        }
    }

    var initialize = function() {
        
        $("#btn-submit-ocr").click(function (e) {
            e.preventDefault();
            var ocravtal = document.getElementsByName('ocravtal');
            var ocrlangd1 = document.getElementsByName('ocrlangd1')[0];
            var ocrlangd2 = document.getElementsByName('ocrlangd2')[0];
            var ocrnummer = document.getElementsByName('ocrnummer')[0];

            submitocr(ocravtal, ocrlangd1, ocrlangd2, ocrnummer);
            return false;
            /* if ($("#result").attr('class') !== '') {
                $('html,body').animate({
                   scrollTop: $("#result").offset().top}, 'slow');
           } */
        });

        $("#btn-clear-ocr").click(function () {
            $('#result').removeAttr('class');

            /* $('html,body').animate({
                scrollTop: $("#ocrForm").offset().top
            }, 'slow'); */ 

        });

        $("input[name='ocravtal']").click(function () {
            $('.ocrLength').css('display', ($(this).val() === 'OCR4') ? 'block' : 'none');
        });
    };
    initialize();
}();