import $ from 'jquery';

module.exports = (function () {
  $('.faq-item-header').on('click', function () {
    var faqItem = $(this).parents('.faq-item');
    if ($('.faq-item-content', faqItem).is(':visible')) {
      faqItem.removeClass('active');
      $('.expand', faqItem).text('+');
      $('.faq-item-content', faqItem).slideUp('fast');
    } else {
      faqItem.addClass('active');
      $('.expand', faqItem).text('-');
      $('.faq-item-content', faqItem).slideDown('fast');
      //var div = faqItem.context;
        var title = faqItem.find('.faq-item-heading').text();
      //var title = div.innerText.substring(2);

      dataLayer.push({
        event: 'event',
        eventCategory: 'Vanliga frågor',
        eventAction: 'Expandera',
        eventLabel: title,
      });
    }

    return false;
  });
})();
