import $  from 'jquery';
import jqueryValidate from 'jquery.validate';
import jqueryValidateUnobtrusive from 'jquery.validate.unobtrusive';
import jqueryUiDatepicker from 'jquery.ui.datepicker';
import foundation from 'foundation';
import foundationDropdown from 'foundation.dropdown';
import foundationOrbit from 'foundation.orbit';
import featherlight from 'featherlight';
import siteseekerAutocomplete from 'siteseekerAutocomplete';
import modifiedPrint from 'modified_print';

import bgMain from './bg/bg.main';
import bgGlobal from './bg/bg.global';
import bgTracking from './bg/bg.tracking';
import bgTransactionSpinner from './bg/bg.transactionSpinner';
import bgSearchpage from './bg/bg.searchpage';
import bgFeedbackform from './bg/bg.feedbackform';
import bgGoogleanalyticstrack from './bg/bg.GoogleAnalyticsTrack'; //self initiating
import softwaremarket from './bg/bg.softwaremarketpage'; //

import moduleBlocklinktabs from './modules/blocklinktabs';
import moduleCategories from './modules/categories'; //self initiating
import moduleContactinfo from './modules/contactinfo'; //self initiating
import moduleDatepicker from './modules/datepicker'; //self initiating
import moduleDropdownmenu from './modules/dropdownmenu';
import moduleFaq from './modules/faq'; //self initiating
import moduleFivestar from './modules/fivestar'; //self initiating
import moduleIetable from './modules/ietable'; //self initiating
import moduleLoadmore from './modules/loadmore';
import moduleMegamenu from './modules/megamenu';
import ModuleSlider from './modules/module-slider';
import moduleOcrfunctions from './modules/ocrfunctions'; //self initiating
import modulePlaceholderpollyfill from './modules/placeholderpollyfill'; //self initiating
import moduleRemodal from './modules/remodal';
import moduleRemodalTipAFriend from './modules/remodal_tipafriend';
import moduleRssbox from './modules/rssbox'; //self initiating
import moduleSoftwaresearchtable from './modules/softwaresearchtable';
import moduleSupport from './modules/support'; //self initiating

import contact from './modules/contact';

(function () {
    /*
        external
    */
    $(window.document).foundation();

    /*
        bg
    */
    if (typeof window.extendGlobal != 'undefined') window.extendGlobal(bgGlobal);
    bgMain.init();
    bgTracking.init();
    softwaremarket.init();

    var $bgSearchpageIdentifier = $('.search.main-searchform');
    if ($bgSearchpageIdentifier.length > -1) bgSearchpage.init();

    bgFeedbackform(); //- validation, unobtrusive, addValidation error

    var $transactionSpinnerInstance = $('.num-of-transactions');
    if ($transactionSpinnerInstance.length > -1) {
        $transactionSpinnerInstance.each(function (index, element) {
            var $self = $(element);
            var peridocity = $self.data('period');
            var transactions = $self.data('number');
            bgTransactionSpinner.init($self, peridocity, transactions);
        });
    }

    /*
        modules
    */
    moduleMegamenu();
    moduleDropdownmenu();
    moduleLoadmore.init();

    var $moduleBlocklinktabsInstance = $('.block-links-tabs');
    if ($moduleBlocklinktabsInstance.length > -1) {
        $moduleBlocklinktabsInstance.each(function () {
            moduleBlocklinktabs($(this));
        });
    }

    $('.software-results, .software-form').each(function () {
        moduleSoftwaresearchtable();
    });

    $('.image-gallery').on('mouseenter', function () {
        $(this).addClass('active');
    });

    $('.image-gallery').on('mouseleave', function () {
        $(this).removeClass('active');
    });

    $('.eq-h-col .large-4 div div:last-child').find('.bigger').addClass('box');

    contact.init();

})();
  