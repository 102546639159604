import jQuery from 'jquery';
import datepicker from 'jquery.ui.datepicker';
import svdatepicker from 'datepicker-sv';
import endatepicker from 'datepicker-en-GB';
import { isDate } from 'util';
//jquery', 'vendor/jquery-ui', 'vendor/datepicker-sv

module.exports = (function () {
    var bankdaysBeforePayment = 1;
    var customDisableClass = 'dateDisabled';
    var serviceType = 1; //default type is autogiro
    var todaysDate = new Date();

    jQuery("[name='servicetype']").on('change', function (e) {
        var value = jQuery(this).val();
        var type = parseInt(value);

        //validate value from radio button, set default type
        if (!(typeof type === 'number' && (type % 1) === 0)) {
            type = 1; //default type
        }

        switch (type) {
            case 1: // Autogiro
                serviceType = 1;
                bankdaysBeforePayment = 1;
                break;
            case 2: // Leverantörsbetalning
                serviceType = 2;
                bankdaysBeforePayment = 1;
                break;
            case 3: // Löner
                serviceType = 3;
                bankdaysBeforePayment = 2;
                break;
        }

        jQuery('.ui-datepicker-current-day').trigger('click');
    });

    var options = jQuery.extend(
      {},                                  // empty object
      jQuery.datepicker.regional["sv"],{
          minDate: '-0m',
          maxDate: '+1y, 11, 31',
          nextText: '',
          prevText: '',
          onSelect: onSelectedDate,
          beforeShowDay: shouldDateBeEnabled,
      });
    if(window.location.pathname.indexOf('/en') > -1){
        options = jQuery.extend(
          {},                                  // empty object
          jQuery.datepicker.regional["en-gb"],{
              minDate: '-0m',
              maxDate: '+1y, 11, 31',
              nextText: '',
              prevText: '',
              onSelect: onSelectedDate,
              beforeShowDay: shouldDateBeEnabled,
          }
      );
    }
    jQuery('#datePicker').datepicker(options);

    jQuery('#datePicker').find('.ui-datepicker-current-day').removeClass('ui-datepicker-current-day');

    //Event triggered when user clicks on any enabled date.
    function onSelectedDate(dateText, instance) {
        var selectedServiceType = '';
        if (serviceType === 1) {
            selectedServiceType = 'Autogiro';
        } else if (serviceType === 2) {
            selectedServiceType = 'Leverantörsbetalningar';
        } else {
            selectedServiceType = 'Löner';
        }
        // Legacy google analytics, left here for as long as it take to make sure that google tag manager is working.
        ga('send', 'event', 'Kalender', selectedServiceType, dateText);
        //Google Tag Manager
        dataLayer.push({
            event: 'event',
            eventCategory: 'Kalender ',
            eventAction: selectedServiceType,
            eventLabel: dateText,
        });
        calculateLastDateOfPayment(dateText);
    }

    //decides wheter or not a date should be enabled or disabled
    function shouldDateBeEnabled(date) {

        //We want to restrict weekends
        if (jQuery.datepicker.noWeekends(date)[0] === false) {
            return [false, customDisableClass];
        }

        ////We want to restrict a number of custom dates
        var formatedDate = jQuery.datepicker.formatDate('yy-mm-dd', date);
        if (restrictedDates.indexOf(formatedDate) !== -1) {
            return [false, customDisableClass];
        }

        //The rest should be enabled
        return [true];
    }

    function returnFirstAvailableDay(date, index) {
        if (!isDate(date)) {
            return new Date();
        }
        var bankDay = shouldDateBeEnabled(date)[0];

        if (bankDay && index === 0) { //its a bank day and the distance is correct == return the date
            return date;
        }

        if (bankDay) {
            index--; //reduce bankdays until we find the nearest day with the closest distance
        }

        date.setDate(date.getDate() - 1); //go back one day

        return returnFirstAvailableDay(date, index);
    }

    function printResult(pickedDate, nearestDate) {
        var text = "";
        var formatedPickedDate = formatDate(pickedDate);
        var nearestDateText = formatDate(nearestDate);

        switch (serviceType) {
            case 1: // Autogiro
                text = formatString(autogiroText, formatedPickedDate);
                break;
            case 2: // Leverantörsbestalning
                text = formatString(distributerText, formatedPickedDate);
                break;
            case 3: // Löner
                text = formatString(salaryText, formatedPickedDate);
                break;
        }
        //if true, tell the user that the nearest paydate is in the past, else show date
        if (isLessThanTodaysDate(nearestDate)) {
            nearestDateText = pickLaterDateText ? pickLaterDateText : "";
            jQuery("p#nearestdate-info").removeClass("hidden");
            jQuery("p#nearestdate").text(nearestDateText).addClass("hidden");
        } else {
            jQuery("p#nearestdate-info").addClass("hidden");
            jQuery("p#nearestdate").text(nearestDateText).removeClass("hidden");
        }

        jQuery("p#information").text(text).removeClass("hidden");
    }

    function isLessThanTodaysDate(nearestDate) {
        if (nearestDate.getYear() > todaysDate.getYear())  return false;
        if (nearestDate.getYear() < todaysDate.getYear()) return true;
        if (nearestDate.getMonth() < todaysDate.getMonth()) return true;
        if (nearestDate.getMonth() > todaysDate.getMonth()) return false;
        if (nearestDate.getDate() < todaysDate.getDate()) return true;
    }

    //helper method to format strings
    function formatString(input) {
        var args = Array.prototype.slice.call(arguments, 1);
        return input.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] !== 'undefined'
            ? args[number]
            : match
            ;
        });
    };

    function formatDate(date) {
        var monthNames = [
          'januari', 'februari', 'mars',
          'april', 'maj', 'juni', 'juli',
          'augusti', 'september', 'oktober',
          'november', 'december',
        ];
        var monthNamesEnGb = [
             'January', 'February', 'March',
             'April', 'May', 'June', 'July',
             'August', 'September', 'October',
             'November', 'December',
        ];
        var dayNames = [
          'söndag', 'måndag', 'tisdag', 'onsdag',
          'torsdag', 'fredag', 'lördag',
        ];
        var dayNamesEnGb = [
              'Sunday', 'Sunday', 'Tuesday', 'Wednesday',
              'Thursday', 'Friday', 'Saturday',
        ];
        var dayIndex = date.getDay();
        var monthIndex = date.getMonth();

        if(document.querySelector('meta[name=category]').getAttribute('content') === 'site;en')
        {
            return dayNamesEnGb[dayIndex] + ', ' + date.getDate() + ' ' + monthNamesEnGb[monthIndex];
        }
        else{
            return dayNames[dayIndex] + ' den ' + date.getDate() + ' ' + monthNames[monthIndex];
}

    }

    function calculateLastDateOfPayment(selectedDate) {
        if (selectedDate.indexOf('/') > 0) {
            var dateParts = selectedDate.split('/');
            selectedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
        }
        var nearestDate = returnFirstAvailableDay(new Date(selectedDate), bankdaysBeforePayment);
        printResult(new Date(selectedDate), nearestDate);
    }
})();
