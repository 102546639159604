import $ from 'jquery';

module.exports = (function () {
  var initialize = function () {
    $('a', 'body').on('click', function (e) {
      trackIfDownload($(this).attr('href'));
    });
  };

  var trackIfDownload = function (href) {
    var filetypes = /\.(zip|pdf|txt|doc|docx|xls|xlsx|ppt|pptx)$/i;

    if (href.match(filetypes)) {
      var extension = getExtensionFromHref(href);
      var file = getFileNameFromHref(href);
      trackDownload(extension, file);
    }
  };

  var trackDownload = function (extension, fileName) {
    if (typeof ga != 'undefined') {
      ga('send', 'event', 'Downloads', extension, fileName);
    }
  };

  var getExtensionFromHref = function (href) {
    var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : '';
    /* extensions is an array (or an empty string if no match is found). Need to get first element */
    if (extension.length > 0) extension = extension[0];
    return extension;
  };

  var getFileNameFromHref = function (href) {
    var file = href;
    var lastPathIndicator = file.lastIndexOf('/');
    if (lastPathIndicator > -1) {
      file = file.substr(lastPathIndicator + 1);
      var extensionIndicator = file.lastIndexOf('.');
      if (extensionIndicator > -1) {
        file = file.substr(0, extensionIndicator);
      }
    }

    return file;
  };

  return {
    init: initialize,
  };
})();
