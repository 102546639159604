﻿import $ from "jquery";

module.exports = function () {
	$("[data-cat]").on("click", function () {
		var $self = $(this);
		// Get the box for this data-cat item
		var box = $self.parents('.rss-box');
		// Get the link contained in this box (in case we have multiple rss boxes out)
		var link = box.find('.rss-result a');
		// Get the reset link in this box
		var reset = box.find('.reset');
		
		// check if it is reset
		if ($self.data('cat') == '*') {
			// If so, remove .active from all items, and add .active to the reset
			reset.addClass('active').siblings('li').removeClass('active');
			// Then reset the rss path
			resetRssPath(link);
		} else {
			$self.parent('li').toggleClass('active');
			// Get the all the active li elements (but not the .reset)
			var items = $self.closest('.rss-categories').find('.active').not('.reset');

			// Toggle reset active-class, and update the rss path
			if (items.length == 0) {
				reset.addClass('active');
				resetRssPath(link);
			} else {
				reset.removeClass('active');
				updateRssPath(items, link);
			}
		}

		return false;
	});

	var resetRssPath = function($link) {
		var base = $link.data('rss-base-path');
		$link.attr('href', base).text(base);
	};

	var updateRssPath = function(active, $link) {
		var ids = [];
		for (var i = 0; i < active.length; i++) {
			ids.push($(active[i]).find('a').data('cat'));
		}

		var newPath = $link.data('rss-base-path') + ids.join();
		$link.attr('href', newPath).text(newPath);
	};
}();