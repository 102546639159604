/*Script for tracking clicks on links on the överskjutande skatt view*/
import $ from 'jquery';

module.exports = (function (context) {
  $('.track a, p .track').each(function () {
    var href = $(this).attr('href');
    var target = $(this).attr('target');
    var text = $(this).text();
    var thisEvent = $(this).attr('onclick');
    $(this).click(function (event) {
      event.preventDefault();
      ga('send', 'event', 'ÖVERSKJUTANDE SKATT', 'link', href);
      setTimeout(function () {
        eval(thisEvent);
        window.open(href, (!target ? '_self' : target));
      }, 300);
    });
  });

  $('li.bank-info-tab, div.mega-menu-inner-bottom.clearfix ul li a').each(function () {
    var text = $(this).text();
    var href = $(this).attr('href');
    $(this).click(function (event) {

      dataLayer.push({
        event: 'event',
        eventCategory: 'Bankspecifika information',
        eventAction: 'flikar',
        eventLabel: href,
      });
    });
  });
  
  
  
  if ($(".bankinfo-accent").length > 0) {
      var target = $(".bankinfo-accent").offset().top,
      timeout = null;
      $(window).scroll(function () {
          var href = $(this).attr('href');
          if (!timeout) {
              timeout = setTimeout(function () {            
                  clearTimeout(timeout);
                  timeout = null;
                  if ($(window).scrollTop() >= target) {
                      dataLayer.push({
                          event: 'event',
                          eventCategory: 'Bankspecifika information',
                          eventAction: 'Inscreen',
                          eventLabel: href,
                      });
                  }
              }, 750);
          }
      });
  }  
})();
