﻿/// <reference path="_references.js" />
import $ from "jquery";
import bg_forms from "./bg.forms";

module.exports = function () {

    var feedbackLink = $('.feedback-form-link');
    if (!feedbackLink)  return;

    var subscribe = function () {
        var container = $('.feedback-form-container');

        feedbackLink.on('click', function (clickEvent) {
            clickEvent.preventDefault();
            var liItem = $(this).parents("li");

            if (container.is(":visible")) {
                $("i", liItem).removeClass("icon-angle-up").addClass("icon-angle-down");
                liItem.removeClass("open");
                container.slideUp('fast');
            } else {
                $("i", liItem).removeClass("icon-angle-down").addClass("icon-angle-up");
                liItem.addClass("open");
                container.slideDown('fast');
            }

        });
    };

    bg_forms.init(function () {
        subscribe();
    });
};