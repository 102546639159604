import $ from "jquery";

module.exports = function () {
	$(".lt-ie10 [placeholder]").each(function() {
		var text 	= $(this).attr("placeholder"),
			elm 	= $(this),
			placeholder = $("<span class='placeholder-pollyfill muted' />").text(text),
			width	= elm.width();
		
		if ($(this).val() == "") {
		    placeholder.show();
		}
		else {
		    placeholder.hide();
		}

		placeholder.css({
			"width": width + "px"
		});
		
		placeholder.on("click", function() {
			elm.focus();
		});
		
		elm.on("keyup", function() {
			if($(this).val() == "") {
				placeholder.show();
			}
			else {
				placeholder.hide();
			}
		});
		
		elm.before(placeholder);	
	});
}();