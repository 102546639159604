import $ from "jquery";

module.exports = function () {
	var saveFiveStarData = function (rate, callback) {
		var pageReference = $("input[name='current-page-id-for-rating']").val();

		$.ajax({
			type: 'POST',
			url: '/api/rating',
			data: { 'ReferenceId': pageReference, 'Value': rate }
		})
			.done(function (msg) {
				callback((msg === 'OK'));
			})
			.fail(function () {
				callback(false);
			});
	};

	var createStars = function () {
		$("[name='rating']").each(function () {
			$(".fivestar-module-container").append('<a class="fivestar-module-star" data-value="' + $(this).val() + '" href="#"><i class="icon-star"></i></a>');
		});
	};

	var enableSelectedStar = function () {
		$("[name='rating']").each(function () {
			var target = $("a[data-value='" + $(this).val() + "']");

			if ($(this).is(":checked")) {
				target.addClass("currentval").prevAll("a").addClass("currentval");
			} else {
				target.removeClass("currentval");
			}
		});
	};

	createStars();
	enableSelectedStar();

	$(".fivestar-module").mouseleave(function () {
		$(".fivestar-module-star").removeClass("selected");
	});

	$(".fivestar-module-star").on("mouseover", function () {
		$(this).prevAll("a").addClass("selected");
		$(this).addClass("selected");
		$(this).nextAll("a").removeClass("selected");
	});

	$(".fivestar-module-star").on("click", function () {
		var fiveStar = $(this);
		var value = fiveStar.attr("data-value");

		saveFiveStarData(value, function (isSaved) {
			var container = $(".fivestar-module-msg");
			var message = '';

			if (isSaved) {
				message = container.data("feedback-message");

				fiveStar.prevAll("input[type='radio'][value='" + value + "']").prop('checked', true);
				enableSelectedStar();
			}

			container.fadeOut('slow', function () {
				container.html(message);
				container.fadeIn('slow');
			});
		});

		return false;
	});
}();