import $ from "jquery";

module.exports = function () {
	// Document is ready
	var ToggleIcon = function(icon) {
		
		if(typeof(icon) != "undefined") {
			if(icon.is(".icon-angle-down")){
				icon.removeClass("icon-angle-down");
				icon.addClass("icon-angle-up");	
			}
			else {
				icon.removeClass("icon-angle-up");
				icon.addClass("icon-angle-down");	
			}
		}
	};
	
	$('body').on('click', function (e) {
		var target = $(e.target);
		if(target.is("[data-dropdown]")) ToggleIcon($("i:first", target));
		else {
			var icon = $("[data-dropdown] i.icon-angle-up");
			ToggleIcon(icon);
		}
	});
	
	$(".bankinfo-extra-toggle").on("click", function() {
		if($(".bankinfo-extra").is(":visible")) {
			$(".bankinfo-extra").slideUp("fast", function() {
				$(".bankinfo").removeClass("open");	
			});
			$(this).removeClass("open");
			$("i", $(this)).removeClass("icon-angle-up");
			$("i", $(this)).addClass("icon-angle-down");
		}
		else {
			$(".bankinfo-extra").slideDown("fast");
			$(".bankinfo").addClass("open");
			$(this).addClass("open");
			$("i", $(this)).removeClass("icon-angle-down");
			$("i", $(this)).addClass("icon-angle-up");
		}
		return false;
	});

	$(".authorityinfo-extra-toggle").on("click", function() {
	    if($(".authorityinfo-extra").is(":visible")) {
	        $(".authorityinfo-extra").slideUp("fast", function() {
	            $(".authorityinfo").removeClass("open");	
	        });
	        $(this).removeClass("open");
	        $("i", $(this)).removeClass("icon-angle-up");
	        $("i", $(this)).addClass("icon-angle-down");
	    }
	    else {
	        $(".authorityinfo-extra").slideDown("fast");
	        $(".authorityinfo").addClass("open");
	        $(this).addClass("open");
	        $("i", $(this)).removeClass("icon-angle-down");
	        $("i", $(this)).addClass("icon-angle-up");
	    }
	    return false;
	});


		
};