import $ from "jquery";

module.exports = function () {

    var $supportServiceCheckboxes = $('.support-services');

    $supportServiceCheckboxes.on("change", function () {

        var $currentElement = $(this);
        var $closestLi = $currentElement.closest('li');
        var $childContainer = $closestLi.find('.detailed-functions');

        if ($currentElement.is(":checked")) {
            $closestLi.addClass('open');

            var $checkboxCollection = $childContainer.find('input[type=checkbox]').not('.accept-terms');
            var $termsCheckbox = $childContainer.find('.accept-terms');

            //Anv�ndaren f�r inte godk�nna villkoren f�rr�n minst en st�dtj�nst �r vald
            toggleTermsDisabledStatus($checkboxCollection, $termsCheckbox);
        }
        else {
            $closestLi.removeClass("open");
        }
    });

    var toggleTermsDisabledStatus = function($checkboxCollection, $termsCheckbox) {

        if ($checkboxCollection.length === 0 && $termsCheckbox.length > 0) {
            $termsCheckbox.removeAttr('disabled');
            return;
        }

        $checkboxCollection.on('change', function () {

            if ($checkboxCollection.is(':checked')) {
                $termsCheckbox.removeAttr('disabled');
            } else {
                $termsCheckbox.attr('disabled', '');
            }

        });
    };

};