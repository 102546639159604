﻿/* english/uk initialisation for the jquery ui date picker plugin. */
/* written by stuart. */
(function (factory) {
    //if (typeof define === "function" && define.amd) {

    //    // amd. register as an anonymous module.
    //    define(["../widgets/datepicker"], factory);
    //} else {

        // browser globals
        factory(jQuery.datepicker);
    //}
}(function (datepicker) {
     
    datepicker.regional["en-gb"] = {
        closeText: "done",
        prevText: "prev",
        nextText: "next",
        currentText: "today",
        monthNames: ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        weekHeader: "Wk",
        dateFormat: "dd/mm/yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    };
    //datepicker.setDefaults(datepicker.regional["en-gb"]);

    //return datepicker.regional["en-gb"];

}));