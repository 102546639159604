﻿import $ from "jquery";

module.exports = function () {

    var $context = $('[data-remodal-id=tipafriend]');

    if ($context.length === -1) return false;

    var clickEventInitialized = false,
        $elm_submit_btn = $context.find('input[type=submit]'),
        $form_tipafriend = $context.find('#modal_tipafriend'),
        $remodal_heading = $context.find('.remodal-heading'),
        $remodal_cancel_btn = $context.find('.remodal-cancel-after-submission'),
        form_isinvalid = function () {
            return $form_tipafriend.find('[data-invalid]').length > 0;
        };

    var resetForm = function () {
        $form_tipafriend.show();
        $remodal_heading.text($remodal_heading.data('original-text'));
        $remodal_cancel_btn.hide();
    };

    var init_form_tipafriend = function () {
        resetForm();

        if (clickEventInitialized)
            return;

        clickEventInitialized = true;
        $elm_submit_btn.on('click', function (e) {
            if (form_isinvalid()) {
                e.preventDefault();
                return false;
            } else {
                e.preventDefault();
                $.post($form_tipafriend.attr('action'), $form_tipafriend.serialize(), function (data) {
                    console.log(data);
                    if (data == true) {
                        $remodal_cancel_btn.show();
                        $form_tipafriend.hide();
                        $("#modal_tipafriend").trigger("reset");
                        $remodal_heading.text($remodal_heading.data('tip-sent-text'));
                    }
                });
            }
        });
    };

    $(document).on('opening', '.remodal', function (e) {
        if ($(e.currentTarget).attr('data-remodal-id') === "tipafriend") init_form_tipafriend();
    });
}();