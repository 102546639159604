﻿/// <reference path="_references.js" />
import $ from "jquery";

module.exports = function () {

    $(".clear-filters-bg").on("click", function (e) {
        e.preventDefault();
        $(this).closest("form").find("input[type='text'], input[type='search']").each(function () {
            $(this).val("");
        });

        return false;
    });

    /*$(".search-button").on("click", function (e) { // TODO: denna måste köras när sökningen har laddat om sidan! Var sker detta? 
        scrollToContent($(".page-header"));
    });*/
    var scrollToContent = function (element) {  
        $('html, body').animate({
            scrollTop: $(element).offset().top
        }, 800);
    };
    var initAdvancedSearch = function () {
        
        //Advanced search
        $(".advanced-search.fold-out").on("click", function () {

            //$(".date-field").datepicker({
            //    firstDay: 1,
            //    dayNamesMin: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
            //    dateFormat: "yy-mm-dd",
            //    selectOtherMonths: true,
            //    showOtherMonths: true,
            //    nextText: "",
            //    prevText: "",
            //    monthNames: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"]
            //});

            //$(".icon-calendar").on("click", function () {
            //    var input = $(this).prev();
            //    input.focus();
            //});

            //$(".date-field").on("change", function () {
            //    if ($(this).val() != "") {
            //        $(this).addClass("active");
            //    }
            //    else {
            //        $(this).removeClass("active");
            //    }
            //});

            $(".clear-filters").on("click", function () {
                var form = $(this).parents("form");
                $("input[type='text'], input[type='search']", form).each(function () {
                    $(this).val("");
                    $(this).trigger("change");
                });

                $("input[type='checkbox']", form).each(function () {
                    $(this).removeAttr('checked');
                });

                $("select", form).each(function () {
                    $(this).prop('selectedIndex', 0);
                });


                return false;
            });

            if ($($(this).attr("href")).is(".hidden")) {
                $("i", this).removeClass("icon-angle-up").addClass("icon-angle-down");
                $($(this).attr("href")).css({
                    "height": $(".search-field-container, .advanced-search-form", $(this).attr("href")).outerHeight() + "px"
                });
                $($(this).attr("href")).removeClass("hidden");
            }
            else {
                $($(this).attr("href")).css({
                    "height": "0px"
                });
                $($(this).attr("href")).addClass("hidden");
                $("i", this).removeClass("icon-angle-down").addClass("icon-angle-up");
            }
	        
            return false;
        });

        //Disabled and help texts
        $("[placeholder]").on("focus", function () {
            $(this).addClass("active");
        });
        $("[placeholder]").on("blur", function () {
            if ($(this).val() == "") {
                $(this).removeClass("active");
            }
        });


        //Result bar
        $(".results-bar.expandable").on("click", function () {
            if ($(".bgnr-results").is(":visible")) {
                $(".bgnr-results").slideUp();
                $(".expand").removeClass("open");
                $(".expand").text("+");
            }
            else {
                $(".bgnr-results").slideDown();
                $(".expand").addClass("open");
                $(".expand").text("-");
            }
            return false;
        });
    };

    // Client-side validation of user input on siteseeker search.
    $('input#quicksearch, input#mainsearch').keyup(AllowOnlyNumber).blur(AllowOnlyNumber);

    function AllowOnlyNumber()
    {
        var v = $(this).val();
        var noCharacters = v.replace(/[*<>()¨;^]/g, '');
        $(this).val(noCharacters); 
    }

    var initialize = function () {
        initAdvancedSearch();

        var resultList = $('.search-result.clearfix');
        var currentCount = $('#currentCount');
        $('#fetchMore').on('done-loading', function ( ) {
            var count = resultList.find('li.large-12.columns').length;
            currentCount.text(count);
        });
    };

    return {
        init: initialize
    };
}();
