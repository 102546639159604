﻿module.exports = function () {

    var GetNoOfDays = function (monthNo) {
        // Number of days per month during a year (leap years ignored)
        var arrDaysPerMonth = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
        var n = 0;
        for (var i = 0; i < monthNo; i++) {
            n += arrDaysPerMonth[i];
        }
        return n;
    };

    var Format = function (num) {
        //
        var strNum = new String(num);
        var output = new String();
        for (var j = 0; j < strNum.length; j++) {
            if ((strNum.substring(j + 1, strNum.length).length % 3 == 0) && (strNum.substring(j + 1, strNum.length).length != 0))
                output = output + strNum.substr(j, 1) + " ";
            else
                output = output + strNum.substr(j, 1);
        }
        return output;
    };

    var Calculate = function (periodicity, transPerPeriod) {
        if (document.getElementById) {
            var d = new Date();
            var seconds, transCurrentTime;
            var daySeconds = 24 * 3600; // Number of seconds per day (24 hours)
            var monthSeconds = 30 * daySeconds; // Calculate with 30 days per month
            var yearSeconds = 365 * daySeconds; // Calculate with 365 days per year
            var days = d.getDate(); // The day number within the current month
            var months = d.getMonth(); // The month, an index that is zero-based (0=January etc.)
            var totalDays = GetNoOfDays(months) + days - 1; // Days up until yesterday

            if (periodicity == "day") {
                seconds = (d.getHours() * 3600) + (d.getMinutes() * 60) + (d.getSeconds()); // Number of seconds up until the current time
                transCurrentTime = parseInt(transPerPeriod * seconds / daySeconds);
            } else if (periodicity == "month") {
                seconds = ((days - 1) * daySeconds) + (d.getHours() * 3600) + (d.getMinutes() * 60) + (d.getSeconds());
                transCurrentTime = parseInt(transPerPeriod * seconds / monthSeconds);
            } else if (periodicity == "year") {
                seconds = ((totalDays) * daySeconds) + (d.getHours() * 3600) + (d.getMinutes() * 60) + (d.getSeconds());
                transCurrentTime = parseInt(transPerPeriod * seconds / yearSeconds);
            }
            _element[0].innerHTML = Format(transCurrentTime) + " ";
        }
    };

    var write = function () {
        Calculate(_periodicity, _transPerPeriod);
        setTimeout(write, 1000);
    };

    var _element = null;
    var _periodicity = null;
    var _transPerPeriod = null;

    var initialize = function (element, periodicity, transPerPeriod) {
        _element = element;
        _periodicity = periodicity;
        _transPerPeriod = transPerPeriod;
        if (_element != 'undefined') {
            write();
        }
    };

    return {
        init: initialize
    };

}();
