import $ from 'jquery';

module.exports = (function () {
    $('[data-category]').on('click', function () {
        $('.category-filter li').removeClass('active');
        $(this).parents('li').addClass('active');
        var cat = $(this).attr('data-category');

        if (cat == '*') $('[data-category-parent]').show();

        else {
            $('[data-category-parent]').hide();
            $('[data-category-parent]').each(function () {
                var $faqItem = $(this);
                var catArray = $faqItem.attr('data-category-parent').split(',');
                $.each(catArray, function (index, value) {
                    if (cat == value) $faqItem.show();
                });
            });
        }

        return false;
    });
})();



