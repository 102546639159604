﻿// autocomplete for siteseeker
jQuery.fn.siteseekerAutocomplete = function (settings) {

	if (!('autocomplete' in this))
		return this;

	if (!settings)
		return this;

	if (!settings.url || !settings.language)
		return this;

	var $that = this;

	this.autocomplete({
		delay: 200,
		source: function (req, res) {
			$.ajax(
				{
					url: settings.url,
					dataType: 'jsonp',
					data: {
						q: $that.val(),
						ilang: settings.language
					},
					success: function (data) {
						res($.map(data, function (item) {
							return {
								label: "<span>" + item.nHits + "</span>" + item.suggestionHighlighted,
								value: item.suggestion
							};
						}));
					}
				});
		},
		appendTo: settings.appendToSelector,
		open: function () {
			var $menu = $that.data('uiAutocomplete').menu.element.css('width', '100%');
			if (settings.menuClass)
				$menu.addClass(settings.menuClass);

			// Checks if wrapClass is provided. If so, checks if menu has a parent with that class.
			// If not, wraps menu with a div with the provided class
			if (settings.wrapClass) {
				if (!$menu.parent().hasClass(settings.wrapClass)) {
					var wrapper = $('<div>').addClass(settings.wrapClass);
					$menu.wrap(wrapper);
				}
			}
		},
		select: function (event, ui) {
			$that.val(ui.item.value);
			$(settings.buttonSelector).click();
		}
	}).data("ui-autocomplete")._renderItem = function (ul, item) {
		return $("<li>")
			.append("<a>" + item.label + "</a>")
			.appendTo(ul);
	};

	return this;
};