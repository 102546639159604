﻿import $ from 'jquery';
//import slick from 'slick-carousel';

let ModuleSlider = {             
    init() {
        let $context = $('.module-slider');
        if (!$context.length) return false;

        ////console.log(slick);
        $context.slick({
            dots: true,
            speed: 500
        });
    },
};

export default ModuleSlider;
