import $ from "jquery";

/************ Module: Mega menu **************/
module.exports = function () {
  var ResetMegaMenuOpenStates = function () {

    //Remove all open classes in menu
    $("[role='navigation'] > li").removeClass("open");

    //Reset all angle icons
    $("[role='navigation'] > li .icon-angle-up").each(function () {
      $(this).removeClass("icon-angle-up");
      $(this).addClass("icon-angle-down");
    });
  };

  var CloseMegaMenu = function () {
    $(".mega-menu").hide();
    ResetMegaMenuOpenStates();
  };

  $("[role='navigation'].left > li").on("click", function (e) {

    var menuId = '#' + $("a.has-mega-menu", $(this)).data("mega-menu");
    var target = $(e.target).parents("li:first");
    var megamenu = $(e.target).parents(".mega-menu");

    if ($(this).is(".open")) {
      if ($("a", target).is(".has-mega-menu")) {
        $(menuId).slideUp("normal", function () {
          CloseMegaMenu();
        });

        return false;
      }
      else {
        return true;
      }
    }
    else {
      if ($("a", target).is(".has-mega-menu")) {
        //Animate?
        var animate = false;
        if ($("[role='navigation'].left > li.open").length == 0) {
          animate = true;
        }

        CloseMegaMenu();
        $(".top-bar-bgnr").hide();
        $("i", $(".bg-search-open")).addClass("icon-angle-down").removeClass("icon-angle-up");
        $(".bg-search-open").removeClass("bg-search-open");
        $("i", $(this)).removeClass("icon-angle-down").addClass("icon-angle-up");
        $(this).addClass("open");
        if (animate) {
          $(menuId).slideDown();
        }
        else {
          $(menuId).show();
        }

        return false;
      }
      else {
        return true;
      }
    }


  });

  $(".close-mega-menu").on("click", function () {
    var megamenu = $(this).parents(".mega-menu");
    megamenu.slideUp("fast", function () {
      CloseMegaMenu();
    });

    return false;
  });
  $(".toggle-bg-search").on("click", function () {
    CloseMegaMenu();
    var liParent = $(this).parents("li");
    if ($(".top-bar-bgnr").is(":visible")) {
      $("i", liParent).addClass("icon-angle-down").removeClass("icon-angle-up");
      $(".top-bar-bgnr").slideUp("fast", function () {
        liParent.removeClass("bg-search-open");
      });
    }
    else {
      liParent.addClass("bg-search-open");
      $(".top-bar-bgnr").slideDown("fast");
      $("i", liParent).addClass("icon-angle-up").removeClass("icon-angle-down");
    }
    return false;
  });

};//module.exports
