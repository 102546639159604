import $ from 'jquery';

module.exports = function (context) {
  $(context).find("a[href^='#']").on('click', function () {
    $('.form-links').removeClass('vis');
    $('li', $(this).parents('.block-links-tabs')).removeClass('active');
    $(this).parents('li').addClass('active');
    $($(this).attr('href')).addClass('vis');
    return false;
  });
};
