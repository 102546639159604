import bgGlobal from './bg.global';
import listjs from 'list.js';

module.exports = (function () {

  var initialize = function () {
    initQuickSearchAutocomplete();
    initializeUnScrambler();
    initAcceptsCookies();
    initFormSwitcher();
    initDataClientSearch();
    initImageLightbox();
  };

  var initializeUnScrambler = function () {
    $('span.email .s').each(function () {
      var atTecken = '&#64;';
      var t = $(this).text();
      t = t.replace('[at]', atTecken);
      var l = '<' + 'a' + ' ' + 'hr' + 'ef=' + 'mai' + 'lto:' + t + '>' + t + '</' + 'a' + '>';
      var parent = $(this).parents('.email');
      parent.html(l);
      parent.show();
    });
  };

  var initQuickSearchAutocomplete = function () {
    if (!('siteSeekerUrl' in bgGlobal)) return;

    $('#quicksearch').siteseekerAutocomplete({
      buttonSelector: '#qsbtn',
      url: bgGlobal.siteSeekerUrl,
      language: bgGlobal.language,
      appendToSelector: '.autocomplete-container',
      menuClass: 'qs-autocomplete',
      wrapClass: 'qs-autocomplete-wrap',
    });

    //Set up positions for autocomplete
    var $topBar = $('.top-bar');
    var $topBarInput = $('.search-field-container input');
    var autoCompleteWidth = ($topBarInput.outerWidth() / $topBar.width()) * 100;
    var inputElmentOffset = $topBarInput.offset();
    var topBarOffset = $topBar.offset();
    var autoCompleteLeftPos = inputElmentOffset.left - topBarOffset.left;

    $('.autocomplete-container').css({
      width: autoCompleteWidth + '%',
      left: autoCompleteLeftPos + 'px',
    });
  };

  var initAcceptsCookies = function () {
    var $cookieAlert = $('#cookiealert');
    $cookieAlert.find('form').one('submit', function () {
      $cookieAlert.remove();
    });
  };

  // Form Switcher: allows for a change of form if the value in an input with class,
  // switch-form matches the regex provided via data-attributes.
  var initFormSwitcher = function () {
    $('.switch-form').closest('form').on('submit', function () {
      var input = $(this).find('.switch-form');
      var regex = input.data('switch-regex');
      if (input.val().match(regex)) {
        var $target = $(input.data('switch-target'));
        $target.val(input.val());
        $target.closest('form').submit();
        return false;
      }

      return true;
    });
  };

  var initDataClientSearch = function () {
    $('[data-client-search]').each(function () {
      var $that = $(this);
      var names = $that.data('client-value-names').split(',');
      var options = {
        valueNames: names,
      };
      var userList = new listjs('form-list', options);
    });
  };

  var initImageLightbox = function () {
    $('.lightbox[href$=".jpg"], .lightbox[href$=".jpeg"], .lightbox[href$=".png"], .lightbox[href$=".gif"]').featherlight();
  };

  return {
    init: initialize,
  };
})();
