﻿/// <reference path="_references.js" />
//import $ from "jquery";
//import validator from "jquery.validate";
//import unobtrusive from "jquery.validate.unobtrusive";
import addValidation from "./../external_assets_modified/jquery.validate.unobtrusive.addValidation";
//import jquery_validate_unobtrusive_addValidation from "./external_assets_modified/jquery.validate.unobtrusive.addValidation";

module.exports = function () {

	var selector = '.form-container';

	var containers = null;

	var updateContent = function (targetContainer, data) {
		targetContainer.html(data);

		var form = targetContainer.find('form');
		$.validator.unobtrusive.addValidation(form);

	    //Recaptcha
		$(".g-recaptcha").each(function (index, item) {
		    $(item).empty();

		    var siteKey = $(item).attr("data-sitekey");
            var rec = grecaptcha.render(item, {
		        'sitekey': siteKey
		    });
		});
	};

	var postForm = function (button) {

		var form = button.closest('form');

		if (!form || !form.valid()) {
			return;
		}

		var promise = $.post(form.attr('action'), form.serialize());

		promise.done(function (data) {
			var targetContainer = button.closest(selector);
			updateContent(targetContainer, data);
		});
	};

	var valueToBool = function($field) {
		if (!$field || $field.length == 0) {
			return false;
		}

		return $field.val().toLowerCase() === 'true' ? true : false;
	};

	var findDescendant = function($container, elementSelector) {
		if (!$container || $container.length == 0) {
			return null;
		}

		return $container.find(elementSelector);
	};

	var findParent = function(el, elementSelector) {
		if (!el) {
			return null;
		}

		return $(el).closest(elementSelector);
	};

	var hasSetPageAfterPost = function(el) {
		var $form = findParent(el, 'form');
		var $field = findDescendant($form, 'input[name=has-page-after-post]');

		return valueToBool($field);
	};

	var subscribe = function () {
		containers.on('click', ':submit', function (clickEvent) {
			if (hasSetPageAfterPost(this)) {
				return;
			}

			clickEvent.preventDefault();

			postForm($(this));
		});

		containers.each(function () {
		    var form = $(this).find('form');
			$.validator.unobtrusive.addValidation(form);
		});
	};

	var initialize = function (callback) {
		containers = $(selector);

	    setTimeout(function() {
	        subscribe();
	    }, 10);
		callback();
	};

	return {
		init: initialize
	};
}();
